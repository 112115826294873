const {protocol, hostname, port} = location

let computedApiBase = ''

const ENV = 'PRODUCTION' // Modificar para qualquer valor ao construir para producao

if (ENV === 'DEVELOPMENT') { // ver package.json
  computedApiBase = `http://localhost:3000`
} else {
  computedApiBase = `${protocol}//${hostname}:${port}`
}

export const apiBase = computedApiBase