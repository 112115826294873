import Vue from "vue";
import App from "./App.vue";
import vuetify from './plugins/vuetify';
import router from "./router";
import { isLogged } from "./services/auth-service";
import { VueMaskDirective } from 'v-mask'

Vue.config.productionTip = false;
Vue.directive('mask', VueMaskDirective);

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !isLogged()) {
    return next({ name: "login" });
  }

  return next();
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
