<template>
  <v-app>
    <v-app-bar app
      color="primary"
      dark>
      <div class="d-flex align-center">
        <router-link to="/">
          <v-btn>INICIO</v-btn>
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <span v-if="isLogged()">{{ email() }}</span>
      <v-tooltip left
        v-if="isLogged()">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon
            v-bind="attrs"
            v-on="on"
            @click="logout"><v-icon>mdi-logout</v-icon></v-btn>
        </template>
        Sair
      </v-tooltip>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { isLogged, logout } from "./services/auth-service";


export default {
  name: 'App',

  data() {
    return {
      email: () => JSON.parse(localStorage.getItem("userData") || "")?.Usuario_email || ""
    };
  },
  methods: {
    logout() {
      logout();
    },
    isLogged() {
      return isLogged();
    },
  }
};
</script>

<style>
small {
  color: gray
}

.container-box {
  border: 1px solid gray;
  margin: 0.5em 1em 1em 0.5em;
  padding: 1em;
}
</style>
