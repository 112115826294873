<template>
  <div>
    <h1>Ferramentas de faturamento</h1>
    <ul>
      <li>
        <router-link to="/empresas">
          <v-btn color="primary">Gestão de empresas e lojas</v-btn>
        </router-link>
        Ajustes de cadastro das lojas, ativação de lojas e de faturamento
      </li>
      <li>
        <router-link to="/faturamento/consolidar">
          <v-btn color="primary">Execuçao de faturamento</v-btn>
        </router-link>
        Consolidação mensal de faturamento
      </li>
      <li>
        <router-link to="/faturamento/snapshot">
          <v-btn color="primary">Visualizar componentes de faturamento</v-btn>
        </router-link>
        Detalhes do faturamento do último período
      </li>
      <li>
        <router-link to="/faturamento/baixar-lote-faturas">
          <v-btn color="primary">Gestão de faturas e baixa de títulos</v-btn>
        </router-link>
        Baixa de titulos por ID da fatura (ids emitidos pelo metabase)
      </li>
      <li>
        <router-link to="/usuarios">
          <v-btn color="primary">Cadastro de usuários</v-btn>
        </router-link>
        Cadastro e gerenciamento de usuários para acesso a este sistema
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
};
</script>

<style scoped>
ul {
  margin-top: 2em
}

li {
  margin-top: 1em;
  list-style: none;
}
</style>
