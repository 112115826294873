import router from '@/router';
import { getAxios } from "../services";

export function login(email, senha) {
    return getAxios().post("/login", { email: email, senha: senha })
        .then(result => {
            const token = result.data.extraData.token;

            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            localStorage.setItem('token', token);
            localStorage.setItem('userData', jsonPayload);
            router.push("/");

        })
        .catch(error => {
            window.alert(error?.response?.data?.message || "Falha ao realizar login");
        });
}

export function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    router.push("/login");
}

export function isLogged() {
    return localStorage.getItem('token') && localStorage.getItem('userData');
}
