import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/empresas",
    name: "empresas",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EmpresasView.vue"),
  },
  {
    path: "/empresas/nova",
    name: "empresas-nova",
    props: true,
    component: () => import(/* webpackChunkName: "about" */ "../views/NovaEmpresa.vue"),
  },
  {
    path: "/empresas/editar/:idempresa",
    name: "empresas-editar",
    props: true,
    component: () => import(/* webpackChunkName: "about" */ "../views/EditarEmpresa.vue"),
  },
  {
    path: "/empresas/editar/:idempresaCluster/fornecedores/novo",
    name: "empresas-editar",
    props: true,
    component: () => import(/* webpackChunkName: "about" */ "../views/NovoFornecedor.vue"),
  },
  {
    path: "/fornecedor/:idfornecedor/:tipoFaturamento",
    name: "fornecedor-editar",
    props: true,
    component: () =>  import(/* webpackChunkName: "about" */ "../views/EditarFornecedor.vue"),
  },
  {
    path: "/faturamento/consolidar",
    name: "faturamento-consolidar",
    props: true,
    component: () => import(/* webpackChunkName: "about" */ "../views/ExecutarFaturamento.vue"),
  },
  {
    path: "/faturamento/snapshot",
    name: "faturamento-snapshot",
    props: true,
    component: () => import(/* webpackChunkName: "about" */ "../views/VisualizarDetalhamento.vue"),
  },
  {
    path: "/faturamento/baixar-lote-faturas",
    name: "faturamento-snapshot",
    props: true,
    component: () => import(/* webpackChunkName: "about" */ "../views/BaixaFaturas.vue"),
  },
  {
    path: "/usuarios",
    name: "usuarios",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UsuariosView.vue"),
  },
  {
    path: "/usuarios/editar/:idusuario",
    name: "usuarios-editar",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EditarUsuario.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
