import router from '@/router';
import axios from 'axios';
import * as config from '../config';
import { logout } from './auth-service';

export const API_ROOT = `${config.apiBase}/api`;
//|| 'http://localhost:3000'
let axiosInstance = axios.create({
  baseURL: API_ROOT,
  timeout: 1000000
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token");
    if (token)
      config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response?.status === 401 && router.currentRoute.path !== "/login") {
      logout();
    }

    if (error.response?.status === 403) {
      window.alert(error.response.data.cause || "Acesso negado");
      router.back();
    }

    return Promise.reject(error);
  }
);

export const getAxios = () => {
  return axiosInstance;
};
